import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Link, useI18next } from 'gatsby-plugin-react-i18next';

import logo from '../../../static/images/BudaEstates_logo_noSlogen_white.png';
import { LanguageSelectorMenu } from '../LanguageSelector';

const drawerWidth = 240;

function ResponsiveAppBar({ pagedataJson }) {
  const {
    Header: { menuItems },
  } = pagedataJson;

  const { t } = useI18next();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      display="flex"
      flexDirection="column"
      onClick={handleDrawerToggle}
      sx={{
        textAlign: 'center',
        alignItems: 'center',
      }}
    >
      <List sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        {menuItems.map(({ name, link }) => (
          <Link
            to={link}
            key={`app-bar-item-${name}`}
            style={{ textDecoration: 'none', color: 'white' }}
          >
            <ListItem key={name} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }}>
                <ListItemText primary={t(`${name}`)} />
              </ListItemButton>
            </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  const container = typeof window !== undefined ? () => window.document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />

      <AppBar
        component="nav"
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.dark,
        })}
      >
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: 'flex', sm: 'flex', md: 'flex' },
              alignItems: 'center',
            }}
          >
            <Link to={'/'} style={{ textDecoration: 'none', display: 'flex' }}>
              <img src={logo} alt="Logo" style={{ height: '50px', marginTop: '3px' }} />
            </Link>
          </Box>

          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
            {menuItems.map(({ name, link }) => (
              <Link to={link} key={`app-bar-item-${name}`} style={{ textDecoration: 'none' }}>
                <Button key={name} sx={{ color: '#fff' }}>
                  {t(`${name}`)}
                </Button>
              </Link>
            ))}
            <LanguageSelectorMenu />
          </Box>

          <Box sx={{ flexGrow: 0, display: { xs: 'flex', sm: 'flex', md: 'none' } }}>
            <LanguageSelectorMenu />
          </Box>
        </Toolbar>
      </AppBar>

      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={(theme) => ({
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              backgroundColor: theme.palette.primary.dark,
            },
          })}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

ResponsiveAppBar.propTypes = {};

export default ResponsiveAppBar;
