import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import ResponsiveAppBar from './AppBar';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        pagedataJson {
          Header {
            logoText
            menuItems {
              name
              link
            }
          }
        }
      }
    `}
    render={({ pagedataJson }) => {
      return <ResponsiveAppBar pagedataJson={pagedataJson} />;
    }}
  />
);
