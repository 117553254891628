module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/Users/work/PROJECTS/budaestates-frontend/src/components/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["hu","en"],"defaultLanguage":"hu","siteUrl":"https://www.budaestates.com","i18nextOptions":{"debug":false,"fallbackLng":"hu","supportedLngs":["hu","en"],"defaultNS":"common","interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/:lang?/properties/:uid","getLanguageFromPath":true},{"matchPath":"/:lang?/properties/:uid/print","getLanguageFromPath":true},{"matchPath":"/:lang?/plots/:uid/print","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Buda Estates - Az Ön megbízható partnere az ingatlanpiacon.","short_name":"Buda Estates","icons":[{"src":"/favicons/icon.png","sizes":"192x192","type":"image/png","purpose":"any"},{"src":"/favicons/icon.png","sizes":"512x512","type":"image/png","purpose":"any"}],"start_url":"/properties/","background_color":"#025361","theme_color":"#025361","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"83cf5f3c78d47629778b4b0fd614bffe"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
