import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

const Copyright = () => (
  <Typography variant="body2" color="text" textAlign={'center'}>
    {' Copyright ©'}
    <Link
      color="inherit"
      href="https://budaestates.com/"
      sx={(theme) => ({
        textDecoration: 'none',
        color: theme.palette.secondary.main,
      })}
    >
      {` budaestates.com `}
    </Link>
    {new Date().getFullYear()}
  </Typography>
);

const StickyFooter = () => {
  return (
    <Box
      component="footer"
      display={'flex'}
      alignItems={'center'}
      sx={{
        py: 3,
        px: 2,
        mt: 'auto',
        backgroundColor: '#cdcdcd',
      }}
    >
      <Container maxWidth="sm">
        <Copyright />
      </Container>
    </Box>
  );
};

export default StickyFooter;
