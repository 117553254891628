import React from 'react';

import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import GlobalStyle from '../components/Common/global-styles';
import Fonts from '../components/Common/fonts';
import theme from '../theme';

import SEO from './SeoComponent';
import AppBar from './AppBar';
import StickyFooter from './StickyFooter/StickyFooter';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh',
        }}
      >
        <SEO />
        <Fonts />
        <GlobalStyle />

        <AppBar />

        <Box
          pt={(theme) => ({
            marginTop: theme.spacing(8),
            [theme.breakpoints.down('sm')]: {
              marginTop: theme.spacing(7),
            },
          })}
        >
          {children}
        </Box>

        <StickyFooter />
      </Box>
    </StyledEngineProvider>
  </ThemeProvider>
);

export default Layout;
