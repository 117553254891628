import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import LanguageIcon from '@mui/icons-material/Language';

import { Link, useI18next } from 'gatsby-plugin-react-i18next';
import { useTheme } from '@mui/material/styles';

const BasicMenu = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const { languages, originalPath, i18n } = useI18next();

  const theme = useTheme();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        aria-label="language-select"
        sx={{ color: '#fff', p: 0, pl: { md: 2 } }}
        onClick={handleClick}
      >
        <LanguageIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {languages.map((lng, i) => (
          <MenuItem onClick={handleClose} key={`menuItem-${i}`}>
            <Link
              to={originalPath}
              language={lng}
              style={{
                textDecoration: i18n.resolvedLanguage === lng ? 'underline' : 'none',
                color: theme.palette.primary.main,
              }}
            >
              {lng}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default BasicMenu;
