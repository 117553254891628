/**
 * Seo component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ lang, meta }) => {
  const data = useStaticQuery(graphql`
    query MetaDataQuery {
      site {
        siteMetadata {
          author
          description
          image
          siteUrl
          title
          titleTemplate
        }
      }
    }
  `);

  const metaImage = `${data.site.siteMetadata.siteUrl}${data.site.siteMetadata.image}`;

  // https://www.gatsbyjs.com/docs/add-seo-component/
  // example: https://github.com/DSchau/gatsby-seo-example/blob/master/src/components/seo.js
  // https://developers.facebook.com/tools/debug

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={data.site.siteMetadata.title}
      titleTemplate={data.site.siteMetadata.titleTemplate}
      meta={[
        {
          name: `description`,
          content: data.site.siteMetadata.description,
        },
        {
          name: `image`,
          content: metaImage,
        },
        {
          property: `og:title`,
          content: data.site.siteMetadata.title,
        },
        {
          property: `og:url`,
          content: data.site.siteMetadata.siteUrl,
        },
        {
          property: `og:description`,
          content: data.site.siteMetadata.description,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: data.site.siteMetadata.title,
        },
        {
          name: `twitter:description`,
          content: data.site.siteMetadata.description,
        },
      ].concat(meta)}
    />
  );
};

SEO.defaultProps = {
  lang: `hu`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

export default SEO;
